<template>
  <header
    class="row w-100 mx-0"
    :class="isMenuOpen ? 'fill-header' : isDarkHeader ? 'dark-header' : ''"
  >
    <div v-if="isDesktop" @click="clickSection('home')" class="col-4 logo">
      <!-- LOGO HEADER -->
      <svg
        width="221"
        height="29"
        viewBox="0 0 221 29"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.55558C0 10.1767 2.93536 13.1111 6.55552 13.1111H8.52007V15.0995H6.55552C2.93444 15.0995 0 18.0349 0 21.655C0 25.2761 2.93536 28.2105 6.55552 28.2105H43.7362V26.5063H10.5084C8.67866 26.5063 7.1954 25.0231 7.1954 23.1933C7.1954 21.3635 8.67866 19.8802 10.5084 19.8802H38.6245C41.448 19.8802 43.7362 17.5912 43.7362 14.7686C43.7362 11.945 41.4471 9.65688 38.6245 9.65688H11.0768C8.93351 9.65688 7.1954 7.91876 7.1954 5.77545C7.1954 3.63214 8.93351 1.89402 11.0768 1.89402H43.7371V0.000976777H6.55644C2.93536 6.00491e-05 0 2.93542 0 6.55558Z"
          fill="currentColor"
        />
        <path
          d="M83.8447 11.857C83.8447 16.3233 80.2246 19.9434 75.7583 19.9434H73.4866C69.0203 19.9434 65.4002 16.3233 65.4002 11.857V9.97951C65.4002 4.46814 60.932 0 55.4207 0C49.9093 0 45.4412 4.46814 45.4412 9.97951V28.2114H47.0821V16.2912C47.0821 11.8249 50.7023 8.20472 55.1686 8.20472C59.6349 8.20472 63.255 11.8249 63.255 16.2912V18.2319C63.255 23.7433 67.7232 28.2114 73.2345 28.2114H75.3806C80.8919 28.2114 85.3601 23.7433 85.3601 18.2319V0H83.8457V11.857H83.8447Z"
          fill="currentColor"
        />
        <path
          d="M115.18 0H87.0635V28.2114H115.18C122.97 28.2114 129.285 21.896 129.285 14.1057C129.285 6.31534 122.97 0 115.18 0ZM119.66 14.1057C119.66 21.0765 114.01 26.7263 107.04 26.7263H88.3891V1.48419H107.04C114.01 1.48419 119.66 7.13489 119.66 14.1057Z"
          fill="currentColor"
        />
        <path
          d="M160.573 0H145.048C137.257 0 130.942 6.31534 130.942 14.1057C130.942 21.896 137.257 28.2114 145.048 28.2114H160.573C168.364 28.2114 174.679 21.896 174.679 14.1057C174.679 6.31534 168.364 0 160.573 0ZM165.148 26.7263H140.473V1.48419H165.147V26.7263H165.148Z"
          fill="currentColor"
        />
        <path
          d="M213.61 13.112C217.231 13.112 220.166 10.1766 220.166 6.55647C220.166 2.93539 217.23 0.000946045 213.61 0.000946045H183.151H181.376C178.644 0.000946045 176.43 2.21577 176.43 4.94762V6.48588L176.335 28.2123H178.039L178.134 5.79925C178.134 3.53768 179.967 1.70515 182.228 1.70515H209.658C211.488 1.70515 212.971 3.18841 212.971 5.0182C212.971 6.84799 211.488 8.33125 209.658 8.33125H184.761C181.937 8.33125 179.649 10.6203 179.649 13.4429C179.649 16.2665 181.938 18.5546 184.761 18.5546H209.09C211.233 18.5546 212.971 20.2927 212.971 22.436C212.971 22.4681 212.968 22.4993 212.967 22.5305V28.2105H220.167V21.655C220.167 18.0339 217.231 15.0995 213.611 15.0995H182.773V13.1111H213.61V13.112Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div class="col-6 logo" @click="clickSection('home')" v-if="!isDesktop">
      <svg
        width="44"
        height="29"
        viewBox="0 0 44 29"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.59401C0 10.2368 2.95301 13.1889 6.59493 13.1889H8.57128V15.1893H6.59493C2.95208 15.1893 0 18.1423 0 21.7842C0 25.4271 2.95301 28.3791 6.59493 28.3791H43.9991V26.6647H10.5716C8.73083 26.6647 7.23865 25.1725 7.23865 23.3317C7.23865 21.4909 8.73083 19.9988 10.5716 19.9988H38.8567C41.6972 19.9988 43.9991 17.6959 43.9991 14.8564C43.9991 12.0159 41.6962 9.71395 38.8567 9.71395H11.1434C8.98721 9.71395 7.23865 7.96537 7.23865 5.80918C7.23865 3.65299 8.98721 1.90443 11.1434 1.90443H44V2.15956e-07H6.59585C2.95301 -0.000922023 0 2.95209 0 6.59401Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div v-if="isDesktop" class="col-8 nav-links">
      <button
        @click="clickSection('home')"
        :class="currentSection == 0 ? 'current-section-line' : ''"
      >
        LOREM IPSUM<span></span>
      </button>

      <button
        @click="clickSection('services')"
        :class="currentSection == 1 ? 'current-section-line' : ''"
      >
        SERVICES<span></span>
      </button>

      <button
        @click="clickSection('duna')"
        :class="currentSection == 2 ? 'current-section-line' : ''"
      >
        DUNA<span></span>
      </button>

      <button
        @click="clickSection('contact')"
        :class="currentSection == 3 ? 'current-section-line' : ''"
      >
        CONTACTS<span></span>
      </button>
    </div>

    <div class="col-6 menu-btn" v-if="isMobile || isTablet">
      <button @click="toggleMenu">
        <svg
          width="43"
          height="9"
          viewBox="0 0 43 9"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0.5H42.5" stroke="currentColor" />
          <path d="M0 8.5H42.5" stroke="currentColor" />
        </svg>
      </button>
    </div>

    <div class="mob-menu" v-if="isMenuOpen" id="mob-menu">
      <div class="wr-btn-menu">
        <button
          @click="
            () => {
              closeMenuMobile();
              clickSection('home');
            }
          "
        >
          LOREM IPSUM
        </button>
        <button
          @click="
            () => {
              closeMenuMobile();
              clickSection('services');
            }
          "
        >
          SERVICES
        </button>
        <button
          @click="
            () => {
              closeMenuMobile();
              clickSection('duna');
            }
          "
        >
          DUNA
        </button>
        <button
          @click="
            () => {
              closeMenuMobile();
              clickSection('contact');
            }
          "
        >
          CONTACTS
        </button>
      </div>
      <div class="mob-menu-circ"><div class="mob-menu-line"></div></div>
    </div>
  </header>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "Header",
  mixins: [checkViewport],
  props: ["isDarkHeader", "currentSection"],

  data() {
    return {
      isMenuOpen: false,
    };
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;

      if (this.isMenuOpen) {
        document.querySelector("body").style.overflowY = "hidden";
        this.scrollPosition = window.pageYOffset;
        document.querySelector("body").style.top = `-${this.scrollPosition}px`;
        document.querySelector("body").style.width = "100%";
      } else {
        document.querySelector("body").style.overflowY = "scroll";
        document.querySelector("body").style.removeProperty("top");
        document.querySelector("body").style.removeProperty("width");
        window.scrollTo(0, this.scrollPosition);
        document
          .getElementById("mob-menu")
          .classList.remove("mob-menu-clicked");
      }

      if (this.isMenuOpen) this.$emit("onOpenMenu");
      else this.$emit("onCloseMenu");
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.querySelector("body").style.overflowY = "scroll";
      document.querySelector("body").style.removeProperty("position");
      document.querySelector("body").style.removeProperty("top");
      document.querySelector("body").style.removeProperty("width");
      this.$emit("onCloseMenu");
    },
    closeMenuMobile() {
      setTimeout(() => {
        this.isMenuOpen = false;
        document.querySelector("body").style.overflowY = "scroll";
        document.querySelector("body").style.removeProperty("position");
        document.querySelector("body").style.removeProperty("top");
        document.querySelector("body").style.removeProperty("width");
        this.$emit("onCloseMenu");
      }, 300);
    },
    clickSection(section) {
      this.$emit("sectionClick", section);
    },
  },
};
</script>
