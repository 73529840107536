import Vue from "vue";
import VueRouter from "vue-router";
import FormView from "../views/FormView.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    /* redirect: "/" */
  },
  {
    path: "/contacts",
    name: "Form",
    component: FormView,
    /* redirect: "/" */
  },
  {
    path: "*",
    name: "Home",
    component: Home,
    /* redirect: "/" */
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          // BEFORE:
          // console.log(to);
          // console.log(to.hash);
          resolve({ selector: to.hash });
        }
        resolve({ selector: "#app" });
      }, 500);
    });
  },
});

export default router;
