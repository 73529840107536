<template>
  <div class="form-view" id="form-view">
    <div id="form-modal-container">
      <div class="form">
        <svg
          @click="closeForm()"
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 13.0492L1 10.9539C6.9148 10.9539 11.0106 6.05037 11.8071 0.0030984L14.3484 0.00309862C13.6648 4.74855 11.3167 8.78109 7.75 10.8361L28 10.7152L28 13.2848L7.75 13.167C11.3137 15.222 13.6648 19.2546 14.3454 24L11.8042 24C11.0076 17.9496 6.91182 13.0492 0.997029 13.0492L1 13.0492Z"
            fill="black"
          />
        </svg>

        <h1>GET IN TOUCH</h1>
        <div id="form-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "FormView",
  mixins: [checkViewport],
  created() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
  mounted() {
    window.hbspt.forms.create({
      region: "eu1",
      portalId: "26084969",
      formId: "affdbab7-a41e-4ff6-9252-e0251270b883",
      target: "#form-container",
    });
  },
  methods: {
    closeForm() {
      // this.$router.push("/#contacts")
      // this.$router.push("/?s=true");
      this.$router.push("/?s=true#contact");
      // this.isFormOpen = false;
      // document.querySelector("body").style.overflowY = "scroll";
      // document.querySelector("body").style.position = "initial";
    },
  },
};
</script>

