<template>
  <div class="loader" id="loader-b">
    <div v-if="!videoReady" class="tmp-loader"></div>
    <video
      class="giff"
      id="giffdesk"
      v-if="isDesktop && loading"
      preload="auto"
      muted
      defaultMuted
      playsinline
      autoplay
      @ended="onVideoEnd"
      :style="{ opacity: videoReady ? 1 : 0 }"
    >
      <source src="../assets/videos/loader_desk_def.mp4" type="video/mp4" />
      <source src="../assets/videos/loader_desk_def.webm" type="video/webm" />
      <source src="../assets/videos/loader_desk_def.ogg" type="video/ogg" />
    </video>
    <video
      v-if="(isMobile || isTablet) && loading"
      id="giffmob"
      class="giff giff-mob"
      playsinline
      autoplay
      preload="auto"
      muted
      defaultMuted
      :style="{ opacity: videoReady ? 1 : 0 }"
      @ended="onVideoEnd"
    >
      <source src="../assets/videos/loader_mob_def.webm" type="video/webm" />
      <source src="../assets/videos/loader_mob_def.ogg" type="video/ogg" />
      <source src="../assets/videos/loader_mob_def.mp4" type="video/mp4" />
    </video>

    <div class="black-loader-ctn">
      <div
        class="black-loader"
        :class="exitAnimationRunning ? 'transparent-loader' : ''"
      >
        <svg
          class="logo-loader"
          viewBox="0 0 221 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 6.55558C0 10.1767 2.93536 13.1111 6.55552 13.1111H8.52007V15.0995H6.55552C2.93444 15.0995 0 18.0349 0 21.655C0 25.2761 2.93536 28.2105 6.55552 28.2105H43.7362V26.5063H10.5084C8.67866 26.5063 7.1954 25.0231 7.1954 23.1933C7.1954 21.3635 8.67866 19.8802 10.5084 19.8802H38.6245C41.448 19.8802 43.7362 17.5912 43.7362 14.7686C43.7362 11.945 41.4471 9.65688 38.6245 9.65688H11.0768C8.93351 9.65688 7.1954 7.91876 7.1954 5.77545C7.1954 3.63214 8.93351 1.89402 11.0768 1.89402H43.7371V0.000976777H6.55644C2.93536 6.00491e-05 0 2.93542 0 6.55558Z"
            fill="white"
          />
          <path
            d="M83.8447 11.857C83.8447 16.3233 80.2246 19.9434 75.7583 19.9434H73.4866C69.0203 19.9434 65.4002 16.3233 65.4002 11.857V9.97951C65.4002 4.46814 60.932 0 55.4207 0C49.9093 0 45.4412 4.46814 45.4412 9.97951V28.2114H47.0821V16.2912C47.0821 11.8249 50.7023 8.20472 55.1686 8.20472C59.6349 8.20472 63.255 11.8249 63.255 16.2912V18.2319C63.255 23.7433 67.7232 28.2114 73.2345 28.2114H75.3806C80.8919 28.2114 85.3601 23.7433 85.3601 18.2319V0H83.8457V11.857H83.8447Z"
            fill="white"
          />
          <path
            d="M115.18 0H87.0635V28.2114H115.18C122.97 28.2114 129.285 21.896 129.285 14.1057C129.285 6.31534 122.97 0 115.18 0ZM119.66 14.1057C119.66 21.0765 114.01 26.7263 107.04 26.7263H88.3891V1.48419H107.04C114.01 1.48419 119.66 7.13489 119.66 14.1057Z"
            fill="white"
          />
          <path
            d="M160.573 0H145.048C137.257 0 130.942 6.31534 130.942 14.1057C130.942 21.896 137.257 28.2114 145.048 28.2114H160.573C168.364 28.2114 174.679 21.896 174.679 14.1057C174.679 6.31534 168.364 0 160.573 0ZM165.148 26.7263H140.473V1.48419H165.147V26.7263H165.148Z"
            fill="white"
          />
          <path
            d="M213.61 13.112C217.231 13.112 220.166 10.1766 220.166 6.55647C220.166 2.93539 217.23 0.000946045 213.61 0.000946045H183.151H181.376C178.644 0.000946045 176.43 2.21577 176.43 4.94762V6.48588L176.335 28.2123H178.039L178.134 5.79925C178.134 3.53768 179.967 1.70515 182.228 1.70515H209.658C211.488 1.70515 212.971 3.18841 212.971 5.0182C212.971 6.84799 211.488 8.33125 209.658 8.33125H184.761C181.937 8.33125 179.649 10.6203 179.649 13.4429C179.649 16.2665 181.938 18.5546 184.761 18.5546H209.09C211.233 18.5546 212.971 20.2927 212.971 22.436C212.971 22.4681 212.968 22.4993 212.967 22.5305V28.2105H220.167V21.655C220.167 18.0339 217.231 15.0995 213.611 15.0995H182.773V13.1111H213.61V13.112Z"
            fill="white"
          />
        </svg>
        <div
          class="button-loader"
          :class="exitAnimationRunning ? 'hide-button' : ''"
          @click="closeLoader()"
        >
          <p>LOREM IPSUM AGENCY</p>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M12.6464 17.3536C12.8417 17.5488 13.1583 17.5488 13.3536 17.3536L16.5355 14.1716C16.7308 13.9763 16.7308 13.6597 16.5355 13.4645C16.3403 13.2692 16.0237 13.2692 15.8284 13.4645L13 16.2929L10.1716 13.4645C9.97631 13.2692 9.65973 13.2692 9.46447 13.4645C9.2692 13.6597 9.2692 13.9763 9.46447 14.1716L12.6464 17.3536ZM12.5 9L12.5 17L13.5 17L13.5 9L12.5 9Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div
        class="black-loader-spacer"
        :class="exitAnimationRunning ? 'transparent-loader' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "Loader",
  mixins: [checkViewport],
  props: ["loading", "exitAnimationRunning"],
  data() {
    return {
      videoReady: false,
    };
  },
  mounted() {
    // this.objFit = "cover";
    // this.height = innerHeight + "px";
    // this.width = innerWidth + "px";
    setTimeout(() => {
      this.videoReady = true;
      // if (this.isDesktop) {
      //   document.getElementById("giffdesk").play();
      // } else {
      //   document.getElementById("giffmob").play();
      // }
    }, 700);
    // const VIDEO_WIDTH_PX = 1920.0;
    // const VIDEO_HEIGHT_PX = 1080.0;

    // window.addEventListener('DOMContentLoaded', event => {
    //     new ResizeObserver(function () {
    //         var element = document.getElementById("video");

    //         var scale;
    //         var transX, transY;
    //         if (element.clientWidth / element.clientHeight > VIDEO_WIDTH_PX / VIDEO_HEIGHT_PX) {
    //             // Screen is wide, so video capped on height.
    //             height = element.clientHeight;
    //             width = (VIDEO_WIDTH_PX / VIDEO_HEIGHT_PX) * height;
    //             scale = element.clientWidth / width;

    //             transY = (-(scale - 1) * height * 0.5) / scale;
    //             transX = 0.0;
    //         } else {
    //             width = element.clientWidth;
    //             height = (VIDEO_HEIGHT_PX / VIDEO_WIDTH_PX) * width;
    //             scale = element.clientHeight / height;

    //             transY = 0.0;
    //             transX = (-(scale - 1) * width * 0.5) / scale;
    //         }

    //         element.style.transformOrigin = "top left";
    //         element.style.transform = "scale(" + scale + ") translate(" + transX + "px, " + transY + "px)";
    //     }).observe(document.getElementById("video"));
    // });
  },
  methods: {
    closeLoader() {
      this.$emit("onCloseClick");
    },
    onVideoEnd() {
      this.$emit("onVideoEnd");
    },
  },
};
</script>

