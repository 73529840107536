import Vue from 'vue'
import App from './App.vue'
import router from './router'


/* STYLESHEETS */
import './themes/global.scss'
import './themes/resp.scss'
/* ///// */

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);





Vue.config.productionTip = false

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
